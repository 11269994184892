<template>
  <v-dialog content-class="confirm-dialog" v-model="dialog" :max-width="480">
    <v-card class="font-inter bg-white">
      <v-card-text class="py-8 px-sm-10">
        <v-flex class="d-flex flex-row justify-space-between">
          <div class="title">
            {{ $t('plans.rerun.title') }}
          </div>
          <button depressed class="d-flex mt-1 ml-4" @click="$emit('close')"><v-icon>mdi-close</v-icon></button>
        </v-flex>
        <div class="mt-4">
          <v-checkbox
            v-for="(item, index) in status"
            :key="index"
            class="text-start"
            :label="item.title"
            hide-details="auto"
          ></v-checkbox>
        </div>
        <v-row class="mt-2">
          <v-col cols="6">
            <v-btn
              depressed
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="$emit('close')"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="primary"
              @click="$emit('rerun')"
            >
              {{ $t('plans.rerun.label') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'select-test-run-status-dialog',
  props: {
    value: Boolean,
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  data() {
    return {
      dialog: this.value,
      status: [
        { title: 'New', value: 'new' },
        { title: 'In progress', value: 'inprogress' },
        { title: 'Passed', value: 'passed' },
        { title: 'Rerun', value: 'rerun' },
        { title: 'Failed', value: 'failed' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  height: unset;
}

.title {
  max-width: 366px;
  font-family: Inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: start;
  color: #0c111d;
}

.description {
  color: #667085;
}

.v-btn.primary {
  background: #f2284e;
  border-radius: 6px;
  color: #ffffff !important;
  text-transform: none;
}
</style>
    